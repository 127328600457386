<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="alertData.relevant === true"
        color="red"
        v-bind="attrs"
        v-on="on"
        >mdi-message-draw</v-icon
      >
      <v-icon
        v-else-if="alertData.relevant === false"
        color="green"
        v-bind="attrs"
        v-on="on"
        >mdi-message-draw</v-icon
      >
      <v-icon v-else v-bind="attrs" v-on="on">mdi-message-draw</v-icon>
    </template>
    <v-list>
      <v-list-item-group v-model="alertData.relevant_status">
        <v-list-item
          v-for="(item, i) in relevantStatuses"
          :key="i"
          :value="item"
        >
          <v-list-item-title
            @click="updateRelevance(alertData, item)"
            :class="`${relevanceColor(item)}--text`"
            >{{ $t(`status.${item}`) }}
          </v-list-item-title>
        </v-list-item>

        <div v-if="alertData.relevant_status">
          <v-divider />
          <v-list-item>
            <v-list-item-title
              @click="updateRelevance(alertData, null)"
              class="text--disabled"
            >
              Non traité
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    alertData: {
      type: Object
    }
  },

  computed: {
    ...mapGetters("analysis", ["relevantStatuses"])
  },

  methods: {
    relevanceColor(item) {
      switch (item) {
        case "FRAUDULENT":
          return "red";
        case "NOT_FRAUDULENT":
          return "text--darken-1 green";
        default:
          return "";
      }
    },

    updateRelevance(alertData, relevantStatus) {
      this.$store.dispatch("alerts/updateAlertRelevance", { alertData, relevantStatus });
    }
  }
};
</script>
